<template>
  <div>
    <div v-if="results.total > 0">Total: {{ results.total }}</div>
    <div style="display: flex; flex-wrap: wrap">
      <div v-for="(group, index) in results.groups" :key="`group-${index}`">
        <div class="result-card" v-for="doc in group.docs" :key="doc.id">
          <ResultCard
            :doc="doc"
            :type="doc._type"
            :highlights="results.highlights[doc.id]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ResultCard from "@/components/ResultCard.vue";
import { SEARCH_ENDPOINT } from "@/env";

export default {
  name: "Search",
  components: {
    ResultCard
  },
  data() {
    return {
      results: {
        docs: [],
        total: 0,
        highlights: {}
      }
    };
  },
  watch: {
    $route(to) {
      this.search(to.query.q, to.query.type, to.query.per_page, to.query.page);
    }
  },
  mounted() {
    this.search(
      this.$route.query.q,
      this.$route.query.type,
      this.$route.query.per_page,
      this.$route.query.page
    );
  },
  methods: {
    async search(query, type, perPage = 100, page = 0) {
      const params = new URLSearchParams();
      params.set("rows", perPage);
      params.set("start", page);
      params.set("q", query);
      if (type) {
        params.append("type", type);
      }

      const response = await fetch(
        `${SEARCH_ENDPOINT}/v1/index/${encodeURIComponent(
          this.$route.params.vertical ?? "concursos"
        )}/search/preview?${params}`
      );

      const result = await response.json();
      this.results = result;
    }
  }
};
</script>
