<template>
  <div>
    <SearchBox
      @preview="onSearchPreview"
      @changeVertical="value => (vertical = value)"
    />
    <!-- <VerticalBox /> -->
    <div @click="drillDown" v-if="results.total > 0">
      Total: {{ results.total }} >
    </div>
    <GroupResults
      v-for="group in results.groups"
      :query="results.query"
      :key="group._type"
      :results="group"
      :highlights="results.highlights"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBox from "@/components/SearchBox.vue";
// import VerticalBox from "@/components/VerticalBox.vue";
import GroupResults from "@/components/GroupResults.vue";

export default {
  name: "Home",
  data() {
    return {
      results: {
        groups: [],
        total: 0,
        query: "",
        highlights: {}
      },
      vertical: "concursos"
    };
  },
  components: {
    SearchBox,
    // VerticalBox,
    GroupResults
  },
  methods: {
    onSearchPreview(results) {
      console.log(results);
      this.results = results;
    },
    drillDown() {
      this.$router.push({
        name: `Search`,
        query: {
          q: this.results.query
        },
        params: {
          vertical: this.vertical
        }
      });
    }
  }
};
</script>
