<template>
  <div class="group-results">
    <div class="group-results-title">{{ title(results._type) }}</div>
    <hr />
    <div style="display: flex">
      <div class="group-result-card" v-for="doc in results.docs" :key="doc.id">
        <result-card :doc="doc" :highlights="highlights[doc.id]" />
      </div>
    </div>
    <div @click="drillDown" class="group-result-total">
      {{ results.total }} resultados em
      {{ title(results._type).toLowerCase() }} >
    </div>
  </div>
</template>

<script>
import mappingType from "../utils/mappingType";
import ResultCard from "./ResultCard.vue";

export default {
  name: "GroupResults",
  components: {
    ResultCard
  },
  props: {
    results: { type: Object, default: () => {} },
    highlights: { type: Object, default: () => {} },
    query: { type: String, default: "" }
  },
  methods: {
    title(type) {
      return mappingType(type);
    },
    drillDown() {
      this.$router.push({
        name: "Search",
        query: {
          q: this.query,
          type: this.results._type
        }
      });
    }
  }
};
</script>

<style scoped>
.group-results {
  margin-bottom: 20px;
}

.group-results-title {
  margin-top: 20px;
}

.group-result-total {
  margin-top: 10px;
  margin-bottom: 10px;
}
.group-result-card {
  display: flex;
  flex-direction: row;
}
</style>
