<template>
  <div>
    <input type="text" @input="onTyping" v-model="searchInput" />
    <select name="vertical" id="vertical" v-model="verticalInput">
      <option value="concursos" selected="selected">Concursos</option>
      <option value="medicina">Medicina</option>
      <option value="vestibulares">Vestibulares</option>
      <option value="militares">Militares</option>
      <option value="carreiras-juridicas">Carreiras Juridicas</option>
      <option value="oab">OAB</option>
    </select>
    <select name="reqType" id="reqType" v-model="reqType">
      <option value="pathParam" selected="selected">Path param</option>
      <option value="header">Header</option>
    </select>
  </div>
</template>

<script>
import _ from "lodash";
import { SEARCH_ENDPOINT } from "@/env";

export default {
  name: "SearchBox",
  data: () => {
    return {
      searchInput: "",
      verticalInput: "concursos",
      reqType: "pathParam"
    };
  },
  watched: {
    verticalInput(value) {
      this.$emit("changeVertical", value);
    }
  },
  methods: {
    onTyping: _.debounce(function() {
      if (this.searchInput == "") {
        this.$emit("preview", {
          groups: [],
          total: 0,
          query: "",
          highlights: {}
        });
        return;
      }

      this.search(this.searchInput, this.verticalInput);
    }, 200),
    async search(term, index) {
      let response = null;
      if (this.reqType === "pathParam") {
        response = await fetch(
          `${SEARCH_ENDPOINT}/v1/index/${encodeURIComponent(
            index
          )}/search/preview?q=${encodeURIComponent(term)}&start=0&rows=3`
        );
      } else {
        response = await fetch(
          `${SEARCH_ENDPOINT}/v1/index/search/preview?q=${encodeURIComponent(
            term
          )}&start=0&rows=3`,
          {
            headers: new Headers({
              "X-Vertical": this.verticalInput
            })
          }
        );
      }
      const result = await response.json();
      console.log(JSON.stringify(result));
      this.$emit("preview", { ...result, query: term });
    }
  }
};
</script>
