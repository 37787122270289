<template>
  <div @click="openResult" class="result-card">
    <div class="result-card-title">
      {{ doc.subtype }}
    </div>
    <div class="result-text" v-html="displayText()"></div>
  </div>
</template>

<script>
import mappingType from "../utils/mappingType";

export default {
  name: "ResultCard",
  props: ["doc", "highlights"],
  computed: {},
  methods: {
    openResult() {
      window.open(this.link);
    },
    title() {
      return mappingType(this.doc.type);
    },
    displayText() {
      if (this.highlights) {
        if (this.highlights["text"].length > 0) {
          return this.highlights["text"][0];
        }
      }

      return this.doc["text"];
    },
    link() {
      const id = this.doc.id.replace(this.doc._type + ":", "");
      switch (this.doc._type) {
        case "cast_track":
          return "about:blank";
        case "cast_album":
          return "about:blank";
        case "question":
          return `https://questoes.estrategiaconcursos.com.br/questoes/${id}`;
      }
      return "about:blank";
    }
  }
};
</script>

<style scoped>
.result-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 150px;
  width: 250px;
  margin: 10px;
}
</style>
